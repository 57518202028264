@import "/src/styles/variables.scss";

.main {
  padding: 10px 20px;
  display: block;
  border-radius: 40px;
  width: fit-content;

  &.primary{
    background: $color-blue-95;
    color: $color-white-75;
    &:not(.disabled):hover{
      background: $color-blue;
      color: $color-white-80;
    }
    &.disabled{
      background: $color-white-10;
      color: $color-white-65;
    }
  }

  &.secondary{
    border: 2px solid $color-blue-80;
    color: $color-white-75;
    &:not(.disabled):hover{
      border: 2px solid $color-blue;
      background: $color-white-05;
      color: $color-white-80;
    }
    &.disabled{
      border: 2px solid $color-gray;
      color: $color-white-65;
    }
  }

}