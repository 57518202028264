$color-secondary: white;
$color-primary: #142666;
$color-primary-05: rgba($color-primary, 0.05);
$color-primary-light: #6C82D4;
$color-highlight: #E97B16;
$color-highlight-75: rgba(233, 123, 22, 0.75);
//$color-primary-85: rgba(253, 216, 53, 0.85);
//$color-primary-45: rgba(253, 216, 53, 0.45);
//$color-primary-15: rgba(253, 216, 53, 0.15);
$color-text: rgba(0, 0, 0, 0.85);
$color-background: rgb(26, 26, 26);

$color-white: #FFF;
$color-white-95: rgba(255, 255, 255, 0.95);
$color-white-90: rgba(255, 255, 255, 0.9);
$color-white-85: rgba(255, 255, 255, 0.85);
$color-white-80: rgba(255, 255, 255, 0.8);
$color-white-75: rgba(255, 255, 255, 0.75);
$color-white-70: rgba(255, 255, 255, 0.70);
$color-white-65: rgba(255, 255, 255, 0.65);
$color-white-60: rgba(255, 255, 255, 0.60);
$color-white-55: rgba(255, 255, 255, 0.55);
$color-white-50: rgba(255, 255, 255, 0.50);
$color-white-40: rgba(255, 255, 255, 0.40);
$color-white-30: rgba(255, 255, 255, 0.30);
$color-white-25: rgba(255, 255, 255, 0.25);
$color-white-20: rgba(255, 255, 255, 0.20);
$color-white-15: rgba(255, 255, 255, 0.15);
$color-white-10: rgba(255, 255, 255, 0.10);
$color-white-05: rgba(255, 255, 255, 0.05);
$color-white-03: rgba(255, 255, 255, 0.03);
$color-white-01: rgba(255, 255, 255, 0.01);

$color-black: rgba(0, 0, 0, 1);
$color-black-90: rgba(0, 0, 0, 0.90);
$color-black-85: rgba(0, 0, 0, 0.85);
$color-black-80: rgba(0, 0, 0, 0.80);
$color-black-75: rgba(0, 0, 0, 0.75);
$color-black-70: rgba(0, 0, 0, 0.75);
$color-black-65: rgba(0, 0, 0, 0.65);
$color-black-60: rgba(0, 0, 0, 0.60);
$color-black-40: rgba(0, 0, 0, 0.40);
$color-black-30: rgba(0, 0, 0, 0.30);
$color-black-20: rgba(0, 0, 0, 0.20);
$color-black-15: rgba(0, 0, 0, 0.15);
$color-black-10: rgba(0, 0, 0, 0.10);
$color-black-05: rgba(0, 0, 0, 0.05);
$color-black-03: rgba(0, 0, 0, 0.03);
$color-black-02: rgba(0, 0, 0, 0.02);
$color-black-01: rgba(0, 0, 0, 0.01);

$color-blue: #334A9E;
$color-blue-95: rgba($color-blue, 0.95);
$color-blue-80: rgba($color-blue, 0.8);
$color-blue-40: rgba($color-blue, 0.4);
$color-blue-30: rgba($color-blue, 0.3);
$color-blue-20: rgba($color-blue, 0.2);
$color-blue-15: rgba($color-blue, 0.15);
$color-blue-10: rgba($color-blue, 0.1);


$color-gray: #F9F9F9;

$color-green: #53a30e;
$color-green-90: rgba(83, 163, 14, 0.9);
$color-green-70: rgba(83, 163, 14, 0.7);
$color-green-40: rgba(83, 163, 14, 0.4);
$color-green-10: rgba(83, 163, 14, 0.1);

$color-red: #CA2C2C;
$color-red-90: rgba(202, 44, 44, 0.9);

$color-yellow: #e7bf34;
$color-yellow-70: rgba(231, 191, 52, 0.7);
$color-yellow-40: rgba(231, 191, 52, 0.4);
$color-yellow-30: rgba(231, 191, 52, 0.3);

$minWidth: 1100px;
$heightHeader: 70px;
$heightHeaderMobile: 56px;

$font-size-1: clamp(2rem, 10vw, 4rem);
$font-size-2: clamp(0.5rem, 5vw, 1.6rem);
$font-size-3: clamp(0.25rem, 4vw, 1.15rem);
$font-size-4: clamp(0.8em, 16px, 1em);