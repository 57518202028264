@import "/src/styles/variables.scss";

html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: $color-white-85;
  background: $color-background;
}

body{

}

#root{
  height: 100%;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;

  &:not(.custom){
    color: $color-primary-light;
    padding: 0 5px
  }
}

* {
  box-sizing: border-box;
}

body, html, #__next {
  height: 100%;
  width: 100%;
}

input {
  border: 0;
  outline: 0;
  background: transparent
}

input:focus {
  outline: none !important;
}

.noSelect,.ns {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// ANIMATIONS

.animate {
  transition: all 0.5s ease;
}

.rotate {
  animation: spin 4s infinite linear;
}

@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

.desk{

}

.mob{

}

.red-text{
  color: $color-red-90
}

.green-text{
  color: $color-green-90
}