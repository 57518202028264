@import "/src/styles/variables.scss";

.main{
  position: relative;

  .icon{
    margin: 0 10px 0 0;
    font-size: 20px;
  }

  .name{
    opacity: 0.65;

    &:hover{
      opacity: 1;
    }
  }

  .list{
    position: absolute;
    top: 99%;
    background: $color-background;
    border: 1px solid $color-white-05;
    border-radius: 5px;
    width: max-content;

    .item{
      display: block;
      padding: 7px 12px;
      color: $color-white-65;

      &:hover{
        background: $color-white-05;
      }
      &.selected{
        //background: $color-white-05;
        color: $color-primary-light
      }
    }
  }
}