@import "/src/styles/variables.scss";

.main{
  width: 100%;
  min-height: 100px;

  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  line-height: calc(30px);
  padding-bottom: 90px;
  color: $color-black-85;
  //border-top: 1px solid $color-black-10;
  font-size: 12px;
  overflow: hidden;
}
