@import "/src/styles/variables.scss";

.main {
  height: 100%;
}


:global(.mob) {
  //overflow: hidden;
  //position: relative;

  .main {
    //position: fixed;
    width: 100%;
  }

  .content {
    overflow: auto;
    width: 100%;
    /* padding-bottom: $heightHeaderMobile;
     overflow: auto;
     height: 100%;
     width: 100%;*/
    padding-bottom:  $heightHeaderMobile;
  }
}

:global(.desk) {
  .main {
    min-width: $minWidth;
    display: flex;
    flex-direction: column;
  }

  //.content {
  //  //padding-top: $heightHeader;
  //  display: flex;
  //  flex-direction: row;
  //  flex: 1 0 auto;
  //
  //}
}