@import "/src/styles/variables.scss";

.main {
  position: fixed;
  width: 100%;
  color: $color-white-75;
  z-index: 100;

  &.open{
    background: $color-background;
    height: 100vh;
  }

  &.scrolled{
    background: $color-background;
    box-shadow: rgb(100 100 111 / 10%) 0px 7px 29px 0px;
  }

  .top{
    padding: 0 0px 0 15px;
    height: $heightHeader;
    .logo{
      .img {
        padding: 0 10px 0 0;
        img {
          max-width: 32px;
          height: fit-content;
        }
      }
      .text{
        padding: 0 10px 0 0;
        font-size: 1.4em;
      }
    }

    .menuButton{
      padding: 0 25px;

      &:hover:active{
        background: $color-white-05;
      }
    }
  }

  .lang{
    padding: 10px;
    margin: 0 10px;
    z-index: 100;

    background: $color-white-03;
    border-radius: 10px;
  }
}

.menu{
  max-height: 100vh;
  overflow: auto;
  padding-bottom: $heightHeader;

  .buttons{
    padding: 20px;
  }

  .menuItem {
    width: 100%;
    position: relative;

    &:hover:active {
      background: $color-white-05;
      .subMenu {
        visibility: visible;
        opacity: 1;
      }
    }

    .link {
      width: 100%;
      padding: 10px 10px 10px 20px;
      font-size: 1.15em;

      &.selected {
        //box-shadow: -3px 0 $color-highlight inset;
        color: $color-primary-light;
      }

      .text {
        white-space: nowrap;

        &:hover {
          color: $color-white-85;
        }
      }

      .iconImg {
        height: 100%;

        span {
          font-size: 28px;
        }

        &:hover {
          color: $color-white-85;
        }
      }
    }

    .subMenu {
      position: absolute;
      top: 100%;
      left: 0;
      background: $color-background;
      border-radius: 0 0 3px 3px;
      padding: 5px 0;
      width: auto;
      //min-width: 170px;

      visibility: hidden;
      opacity: 0;
      -webkit-transition: all 0.1s linear;
      -moz-transition: all 0.1s linear;
      transition: all 0.1s linear;

      .subMenuItem {
      }

      .link2 {
        min-height: 35px;
        padding: 7px 40px 7px 10px;
        margin: 0 0 0 3px;

        .textSubMenu {
          white-space: nowrap;
          position: relative;

          &:hover {
            color: $color-white-85;
          }
        }

        &.selected {
          box-shadow: 3px 0 $color-highlight inset;
        }

        &.selectedSubMenu {
          .textSubMenu:before{
            background: $color-highlight;
            height: 29px;
            width: 3px;
            position: absolute;
            left: -8px;
            top: -5px;
            content: "";
          }
        }

      }
    }

    .subMenuList {
      margin: 0;
      padding: 0 0 0 20px;
      margin-left: 17px;


      .subMenuListItem {
        .link3 {
          min-height: 35px;
          position: relative;

          &:before {
            position: absolute;
            border-radius: 5px;
            height: 8px;
            width: 8px;
            background: $color-white-80;
            content: '';
            left: -20px;
            top: calc(50% - 5px);
          }

          &.selected {
            &:before {
              background: $color-highlight;
            }
          }
        }
      }
    }
  }
}
